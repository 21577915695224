<template>
  <b-card class="p-1">
    <b-row v-if="operation">
      <loading :active.sync="isLoading" :is-full-page="false"></loading>
      <b-col sm="12" md="7" lg="7">
        <div class="collection">
          <OperationForm
            :client="client"
            :item="operation"
            duplicate
            @save="save($event)"
          />
        </div>
      </b-col>
      <b-col class="hidden-sm-down" md="5" lg="4">
        <div v-if="client && client.collection === 'client'">
          <h3 class="title">DATOS BASICOS</h3>
          <RowComponent :title="'ID:'" :content="client.id.toString()" />
          <RowComponent :title="'EMAIL:'" :content="client.email" />
          <RowComponent :title="'CUIT/DNI:'" :content="client.dni" />
          <RowComponent :title="'NOMBRE:'" :content="client.name" />
          <RowComponent :title="'APELLIDO:'" :content="client.last_name" />
        </div>

        <div
          v-if="client && client.collection === 'client' && client.type === 'post-pago'"
        >
          <h2 class="title">DIRECCIÓN DE COLECTA</h2>
          <RowComponent
            :title="'PROVINCIA:'"
            :content="
              client.direction.province instanceof String
                ? client.direction.province
                : client.direction.location.province.province
            "
          />
          <RowComponent
            :title="'LOCALIDAD:'"
            :content="
              client.direction.location instanceof String
                ? client.direction.location
                : client.direction.location.location
            "
          />
          <RowComponent :title="'CALLE:'" :content="client.direction.street" />
          <RowComponent :title="'ALTURA:'" :content="client.direction.height" />
          <RowComponent
            :title="'CODIGO POSTAL:'"
            :content="client.direction.postal_code"
          />
        </div>

        <div
          class="collection p-1 d-none d-md-block"
          v-if="client && client.collection === 'deposit' && client.point"
        >
          <h3 class="text-nowrap font-weight-bold title">DEPOSITO</h3>
          <hr>
          <h3 class="text-wrap font-weight-bold title">
            DROPOFF:
            <span class="text-muted">Aquí debes entregar el paquete</span>
          </h3>

          <b-form-group label="Provincia">
            <b-form-input
              size="sm"
              v-model="client.point.location.province.province"
              type="text"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Localidad">
            <b-form-input
              size="sm"
              v-model="client.point.location.location"
              type="text"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Calle">
            <b-form-input
              size="sm"
              v-model="client.point.street"
              type="text"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Altura">
            <b-form-input
              size="sm"
              v-model="client.point.height"
              type="text"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Código Postal">
            <b-form-input
              size="sm"
              v-model="client.point.postal_code"
              type="text"
              disabled
            ></b-form-input>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import OperationService from "@/services/OperationService";
import UserService from "@/services/UserService";

// import SelectCustomer from "./components/SelectCustomer";
import OperationForm from "./OperationForm";

export default {
  name: "OperationDuplicate",
  components: {
    // SelectCustomer,
    OperationForm,
  },
  data() {
    return {
      operation: null,
      client: null,
      isAdmin: UserService.isAdminOrStaff(),
    };
  },
  methods: {
    async loadData() {
      const { data } = await OperationService.show(this.$route.params.id);
      const operation = data.data;
      let client = null;
      if (UserService.isAdminOrStaff()) {
        const { data } = await UserService.show(operation.user.id);
        client = data.data;
      } else {
        client = UserService.user();
      }
      return { client, operation };
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadData()
      .then(({ client, operation }) => {
        this.client = client;
        this.operation = operation;
        this.operation.retirement_item = this.operation.retirement_addresses;
        this.operation.collection_point = this.operation.retirement_addresses;
        this.isLoading = false;
      })
      .catch((thrown) => {
        this.isLoading = false;
        console.error(thrown);
      });
  },
};
</script>
